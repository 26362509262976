// import './common/utils/debug/whyDidYouRender'
import './polyfills.ts'
import './index.css'
import './window'

import { ThemeProvider } from '@fattureincloud/fic-design-system'
import VaporThemeProvider from '@vapor/react-material/styles'
import React, { Suspense } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './components/app/App'
import LoadingOverlay from './components/common/LoadingOverlay'
import { theme } from './config/theme'
import FIC from './old/common/general/js/FIC'
import store from './redux'

FIC.init(theme)

const router = createBrowserRouter([{ path: '*', element: <App /> }])

render(
  <Provider store={store}>
    <VaporThemeProvider name='fic'>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingOverlay forceLoading />}>
          <RouterProvider router={router} />
        </Suspense>
      </ThemeProvider>
    </VaporThemeProvider>
  </Provider>,
  document.getElementById('root')
)
