import { IssuedDocumentHelperLink } from '../../old/invoices/new/ts/components/issuedDocument/IssuedDocumentHelper'
import { TSPayPOSPayments } from '../../redux/features/swapStorage'
import { TSPayStore } from '../../redux/features/tsPay'
import { Language, PaymentStatus, UnknownObject } from '.'
import { Currency, PaymentTerm } from './api'
import { DocumentType } from './documents'

// Temporary the old issued document form types are here
// I needed some of these in other parts and we will need them when the new form will be remade

export type StringCountry = string

export type OldPaymentListItem = {
  amount: number
  due_date: string | null
  ei_raw: Record<string, unknown> | null
  id: number
  paid_date: string
  payment_account: OldPaymentAccount | null
  payment_terms: PaymentTerm | number | null
  status: PaymentStatus.NOT_PAID | PaymentStatus.PAID
}

export type PriceListItem = {
  id: string
  isArchived: boolean
  isDefault: boolean
  name: string
  priceType: string
  validFrom: Date
  validTo: Date
}

export type OldPaymentAccount = { id: number; name: string; virtual?: boolean }

export type IssuedDocumentFormParams = {
  company_type: string
  hasEnasarco: boolean
  mode: string
  tsPay: TSPayStore
  tsPayPOSPayments: TSPayPOSPayments | undefined
}

export type IssuedDocumentFormDocument = {
  _amount_due_discount_enabled: boolean
  _amount_due_type: string
  _cassa_enabled: boolean
  _cassa_taxable_format: CassaFormat
  _global_cassa_enabled: boolean
  _global_cassa_taxable_format: CassaFormat
  _has_enasarco_custom_taxable: boolean
  _other_withholding_tax_enabled: boolean
  _other_withholding_tax_taxable_format: string
  _pos_total_amount: number
  _rivalsa_enabled: boolean
  _rivalsa_taxable_format: CassaFormat
  _withholding_tax_enabled: boolean
  _withholding_tax_taxable_format: CassaFormat
  attachment_url: string
  cassa: number
  cassa2: number
  currency: Currency
  date: string
  e_invoice: boolean
  ei_other_withholding_tax_type: OtherWithholdingsCodes
  ei_status: string
  entity: Entity
  entity_mode?: EntityMode
  has_ts_pay_pending_payment: boolean
  id: number
  locked: boolean
  number: number
  numeration: string
  other_withholding_tax: number
  rivalsa: number
  show_tspay_button: boolean
  type: DocumentType | null
  withholding_tax: number
  year: number
}

export type Entity = {
  address_city: string
  address_extra: string
  address_postal_code: string
  address_province: string
  address_street: string
  certified_email: string
  country: StringCountry
  default_discount: number
  default_vat: { id: number; value: number; description: string }
  discount_highlight: boolean
  ei_code: string
  ei_regime: string
  email: string
  entity_type: 'client' | 'supplier'
  first_name: string
  has_intent_declaration: boolean
  id: number
  intent_declaration_protocol_date: string | null
  intent_declaration_protocol_number: number | null
  last_name: string
  name: string
  original_name: string | undefined
  shipping_address: string
  tax_code: string
  type: string | null
  vat_number: string
}

export type CassaFormat = 'fixed_value' | 'percentage'

// (ಠ_ಠ) WTF
export type IssuedDocumentFormVatList =
  | number
  | Record<number, { 0: number; 1: number }>

export type IssuedDocumentFormTotals = {
  amount_cassa_taxable: number
  amount_cassa2_taxable: number
  amount_cassa: number
  amount_cassa2: number
  amount_due_discount: number
  amount_due: number
  amount_enasarco_taxable: number
  amount_global_cassa_taxable: number
  amount_gross: number
  amount_net_with_rivalsa: number
  amount_net: number
  amount_other_withholding_tax_taxable: number
  amount_other_withholding_tax: number
  amount_rivalsa_taxable: number
  amount_rivalsa: number
  amount_vat: number
  amount_withholding_tax_taxable: number
  amount_withholding_tax: number
  not_taxable_amount: number
  stamp_duty: number
  taxable_amount: number
  vat_list: IssuedDocumentFormVatList
}

export type OtherWithholdingsCodes = 'RT03' | 'RT04' | 'RT05' | 'RT06'

export const otherWithholdingsNameMapping: Record<
  OtherWithholdingsCodes,
  string
> = {
  RT03: 'Ritenuta INPS',
  RT04: 'ENASARCO',
  RT05: 'Ritenuta ENPAM',
  RT06: 'Altra ritenuta',
}

export enum EntityMode {
  SAME_CP_CC = 'same',
  REVERSE_CP_CC = 'reverse',
}

export type EIRegimeInfoMap = {
  label: string
  value: string
}

export type DNTemplate = {
  id: number
  name: string
  supports_custom_taxable: boolean
}

export type IssuedDocumentFormState = {
  citiesList: (IssuedDocumentCity & { label: string })[]
  countriesList: { country: StringCountry }[]
  currenciesList: Currency[]
  dnTemplatesList: DNTemplate[]
  document: IssuedDocumentFormDocument
  eiRegimeInfo: EIRegimeInfoMap[]
  languagesList: Language[]
  numerations: Record<number | string, Record<string, number>>
  totals: IssuedDocumentFormTotals
}

export type IssuedDocumentFormProps = {
  params: IssuedDocumentFormParams
}

export type IssuedDocumentFormMethodsAndProps = {
  handleCitySelect: (city: IssuedDocumentCity) => void
  handleEntityBlur: (e: Event) => void
  helper: () => IssuedDocumentHelperLink
  loadCities: (search: string) => void
  onSwitchChange: () => void
  plan: number
  saveEntity: () => void
  setAttributes: (attrs: UnknownObject) => void
  setClientPriceListId: (id: string) => void
  setPreviousClient: (clientData: Entity) => void
}

export type IssuedDocumentForm = IssuedDocumentFormMethodsAndProps &
  React.Component<IssuedDocumentFormProps, IssuedDocumentFormState>

export type IssuedDocumentCity = {
  city: string
  postal_code: string
  province: string
}
