import { ValidationResult, ValidatorParams } from './types'
import { Validation } from './validatorResultMaker'

const EI_CODE_REGEX = /^[A-Z0-9]{7}$/
const PA_EI_CODE_REGEX = /^[A-Z0-9]{6}$/

export const validateEiCode = (
  eiCode: string,
  params?: ValidatorParams
): ValidationResult => {
  if (eiCode === '' && params?.allowEmpty) {
    return Validation.success()
  }

  if (!eiCode && !params?.allowEmpty) {
    return Validation.error('Il codice destinatario non può essere vuoto.')
  }

  if (EI_CODE_REGEX.test(eiCode)) {
    return { valid: true }
  }

  return Validation.error('Il codice destinatario non sembra corretto.')
}

export const validatePAEiCode = (
  eiCode: string,
  params?: ValidatorParams
): ValidationResult => {
  if (eiCode === '' && params?.allowEmpty) {
    return Validation.success()
  }

  if (!eiCode && !params?.allowEmpty) {
    return Validation.error('Il codice PA non può essere vuoto.')
  }

  if (PA_EI_CODE_REGEX.test(eiCode)) {
    return { valid: true }
  }

  return Validation.error('Il codice PA non sembra corretto.')
}
