import { ValidationResult } from './types'
import { Validation } from './validatorResultMaker'

const ONLY_NUMBERS_REGEX = /^\d*$/

/**
 * Number validator
 * @param {string} s - The string to validate
 * @return {ValidationResult}
 */
export const validateNumber = (s: string): ValidationResult => {
  return ONLY_NUMBERS_REGEX.test(s)
    ? Validation.success()
    : Validation.error('Il campo può contenere solo numeri.')
}

/**
 * Check if a telephone number is valid
 * @param {string} phoneNumber
 *
 * @return {ValidationResult}
 */
export const validatePhoneNumber = (
  phoneNumber: string,
  allowEmpty = false
): ValidationResult => {
  // Digits, spaces, dashes, plus and brackets are allowed
  if (/[^0-9\-+\s()]+/g.test(phoneNumber)) {
    return Validation.error('Il numero di telefono non è valido.')
  }
  // This is needed to remove spaces, dashes, etc. and check only digits
  phoneNumber = phoneNumber.replace(/\D/g, '')
  return allowEmpty && phoneNumber.length === 0
    ? Validation.success()
    : phoneNumber.length >= 5 && phoneNumber.length <= 15
    ? Validation.success()
    : Validation.error('Il numero di telefono non è valido.')
}
