import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { batch, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import FIC from '../../old/common/general/js/FIC'
import {
  GlobalParamsApiResponse,
  setFromApi,
} from '../../redux/app/actions/setFromApi'
import { setConnectionId } from '../../redux/features/auth/connections/connectionsSlice'
import { setSession } from '../../redux/features/auth/currentSession/currentSessionSlice'
import { setSurveyToken } from '../../redux/features/auth/person/personSlice'
import ConfigurationsStore from '../../redux/features/configurations/configurationsStoreTypes'
import { setGaTags } from '../../redux/features/gaTags/gaTagsSlice'
import { setOuterLoading } from '../../redux/features/loaders/loadersSlice'
import {
  LocalStorageStore,
  setHasAutomaticRenewal,
  setLocalStorage,
} from '../../redux/features/localStorage'
import { toggleModal } from '../../redux/features/modals/modalsSlice'
import { clearImportedProductsData } from '../../redux/features/swapStorage'
import { isDevelopment, isProduction } from '../environment'
import Api from '../network/Api'
import { loadReactGlobalParams } from '../utils/loadReactGlobalParams'
import { logout } from '../utils/logout'
import { absolutePath } from '../utils/urlUtils'
import { updateNotificationsCounter } from './useNotificationsPolling'

interface ChangeConnectionOptions {
  wantsManageCompany?: boolean
  goTo?: string
  showLoading?: boolean
}

const defaultOptions: ChangeConnectionOptions = {
  wantsManageCompany: true,
  showLoading: true,
}

type ChangeCompanyType = (
  accessToken: string,
  connectionId: number,
  options?: ChangeConnectionOptions
) => Promise<void>

export interface AuthReturnType {
  refreshAuth: (params?: RefreshAuthInterface) => Promise<void>
  changeCompany: ChangeCompanyType
  getAppConfig: () => Promise<AxiosResponse<GetAppConfigResponse>>
}

interface RefreshAuthInterface {
  goTo?: string | null
  credentials?: {
    accessToken?: string
    connectionId?: number
  }
  lastAccessTokenAction?: LocalStorageStore['lastAccessTokenAction']
  keepLoading?: boolean
}

type GetAppConfigResponse = {
  data: ConfigurationsStore
}

const urlExceptions = [
  '/settings/subscriptionsthanks',
  '/settings/subscriptionsthanks/renew',
  '/settings/freereactivation',
  '/accountant',
  '/accountant_com',
]

// Al cambio azienda redirect a "to" se pathname è "url"
const urlRedirectsOnCompanyChanged = [
  { from: '/expenses/new', to: '/expenses' },
  { from: '/expenses/view', to: '/expenses' },
  { from: '/expenses/edit', to: '/expenses' },
  { from: '/expenses/duplicate', to: '/expenses' },
  { from: '/invoices/view', to: '/invoices' },
  { from: '/invoices/edit', to: '/invoices' },
  { from: '/invoices/reverse', to: '/invoices' },
  { from: '/invoices/duplicate', to: '/invoices' },
  { from: '/recurring/invoice', to: '/recurring' },
  { from: '/recurring/expense', to: '/recurring' },
  { from: '/clients/analysis', to: '/clients' },
  { from: '/suppliers/analysis', to: '/clients' },
]

const getAppConfig = (): Promise<AxiosResponse<GetAppConfigResponse>> =>
  Api.get<GetAppConfigResponse>({
    request: 'get_app_config',
    rawResponse: true,
  }).then(response => {
    window.reactGlobalParams = {
      ...(window.reactGlobalParams ?? {}),
      ...response.data.data,
    }
    FIC.setGlobals(response.data.data)
    return response
  })

const useAuth = (): AuthReturnType => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleError = useErrorHandler()

  const refreshAuth = useCallback(
    async ({
      credentials,
      goTo,
      keepLoading = false,
      lastAccessTokenAction,
    }: RefreshAuthInterface = {}) => {
      return Api.get<GlobalParamsApiResponse>({
        customToken: credentials?.accessToken,
        request: 'global_config',
        queryData: {
          support: localStorage.getItem('support'),
        },
      })
        .then(response => {
          const { redirectUri, reduxData } = response.data.data
          const { auth } = reduxData

          goTo = redirectUri || goTo

          dispatch(setSession({ session: auth.currentSession }))
          localStorage.setItem('currentSession', auth.currentSession)
          localStorage.setItem(
            'need_setup',
            String(Number(auth?.companyDetails?.needSetup || 0))
          )

          loadReactGlobalParams(reduxData)

          batch(() => {
            dispatch(setFromApi(reduxData))
            dispatch(setGaTags(response.data.extra.gaTags))
            setHasAutomaticRenewal(
              auth.companyDetails?.hasAutomaticRenewal ?? false
            )

            const { needEditVats } = auth.person
            dispatch(
              toggleModal({
                name: 'showEditAliquotaModal',
                visible: needEditVats,
              })
            )

            if (credentials) {
              const { accessToken, connectionId } = credentials
              connectionId && dispatch(setConnectionId({ connectionId }))

              accessToken &&
                dispatch(
                  setLocalStorage({
                    accessToken,
                    lastAccessTokenAction:
                      lastAccessTokenAction ?? 'changedCompany',
                    hasuraAccessToken: '',
                  })
                )
            }

            credentials?.accessToken && updateNotificationsCounter()
            if (goTo) {
              navigate(absolutePath(goTo))
            }
          })

          if (!keepLoading) {
            dispatch(setOuterLoading({ isLoading: false }))
          }
        })
        .catch(error => {
          if (
            error?.response &&
            error.response.status === 401 &&
            (isProduction || isDevelopment)
          ) {
            logout()
          } else if (error?.message === 'Request aborted') {
            handleError({
              name: 'errorUseAuth1',
              message:
                'Ti preghiamo di ricaricare la pagina in quanto il caricamento è stato interrotto.',
            })
          } else {
            handleError({
              name: 'errorUseAuth1.1',
              message: 'Si è verificato un errore imprevisto',
            })
          }
        })
    },
    [dispatch, handleError, navigate]
  )

  const changeCompany = useCallback<ChangeCompanyType>(
    async (accessToken, connectionId, options) => {
      const opt: ChangeConnectionOptions = { ...defaultOptions, ...options }

      const goTo =
        opt.goTo ||
        (urlRedirectsOnCompanyChanged.filter(item =>
          window.location.pathname.includes(item.from)
        )[0]?.to ??
          (urlExceptions.includes(window.location.pathname) ||
          /(-\d+)$/.test(window.location.pathname)
            ? '/situation'
            : undefined))

      batch(() => {
        dispatch(setSurveyToken({ token: null }))
        opt.showLoading && dispatch(setOuterLoading({ isLoading: true }))
      })

      dispatch(setLocalStorage({ wantsManageCompany: opt.wantsManageCompany }))
      dispatch(clearImportedProductsData())

      await Api.get({
        url: window.location.origin,
        request: `/rest.php?request=change_connection&conid=${connectionId}&wantsToManageCompany=${
          opt.wantsManageCompany ? 'true' : 'false'
        }`,
      })

      const credentials = { accessToken, connectionId }
      await refreshAuth({ goTo, credentials })
    },
    [dispatch, refreshAuth]
  )

  return {
    refreshAuth,
    changeCompany,
    getAppConfig,
  }
}

export default useAuth
