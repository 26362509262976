import { BECallStatus } from '../../../common/types'
import PreferencesStore from './preferencesStoreTypes'

const preferencesInitialState: PreferencesStore = {
  accountantDashboardTourConf: { showAt: null, dismissCount: 0 },
  closedSubaccountsFeatureInfoModal: false,
  expensesAirSeenFirstTime: undefined,
  expensesAirShowPreviewTooltipCount: 0,
  expensesAirTableSettings: {
    dimensionRow: 42,
    notVisibleColumns: [''],
    orderColumns: [''],
  },
  expensesListTableSettings: {
    orderColumns: [''],
    dimensionRow: 42,
    notVisibleColumns: [''],
  },
  fetchStatus: BECallStatus.IDLE,
  hiddenModules: [],
  lastExpensesAirSync: '',
  sendAutomaticRenewalEmailReminder: false,
  showBankReconciliationModal: true,
  showBankStatementRoundingModal: true,
  showCallDropdownTour: false,
  showCompanyPickerHotkey: true,
  showCompanyPickerTooltip: true,
  showDuplicatesNamesAccountant: true,
  showFailedCheckCreditCardBanner: false,
  showFirstEmptyStateExpensesAir: true,
  showNewExpensesAirTour: false,
  showNewExpensesCreation: undefined,
  showNewExpensesFirstAttachmentsTooltip: false,
  showNewExpensesFirstDocumentUploadTooltip: false,
  showNewExpensesFirstMiningTooltip: false,
  showNewExpensesFirstMultipleUploadTooltip: false,
  showNewExpensesFirstSupplierSavedTooltip: false,
  showNewExpensesTour: false,
  showOtherPaymentsButton: false,
  showSidebarTour: false,
  sidebarBookmarkKeys: [],
  skipExpenseImportHelper: false,
  hideReportGenerateModal: false,
  forceShowQuartersPage: false,
  hideCategoryAssociationPageTour: false,
  priceListEnabled: false,
}

export default preferencesInitialState
