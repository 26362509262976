import { PayloadAction } from '@reduxjs/toolkit'

import { ImportedProductsData } from '..'
import { importedProductsDataInitialState } from '../swapStorageInitialState'

export const setImportedProductsData = (
  state: any,
  action: PayloadAction<ImportedProductsData>
): void => {
  state.importedProductsData = action.payload
}

export const clearImportedProductsData = (state: any): void => {
  state.importedProductsData = importedProductsDataInitialState
}
