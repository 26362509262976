import { Card, Modal } from '@fattureincloud/fic-design-system'
import React, { CSSProperties } from 'react'
import styled, { css } from 'styled-components/macro'

import { flexColumn } from '../../common/styles'
import SearchInput from './table/components/searchInput/SearchInput'

export const NoWrapWord = styled.span`
  white-space: nowrap;
`

type FlexProps = {
  centered?: boolean
  flex?: number
  gap?: number
}
const flexCSS = css<FlexProps>`
  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

    ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`

export const FlexRow = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${flexCSS}
`

export const FlexColumn = styled.div<FlexProps>`
  display: flex;
  flex-direction: column;
  ${flexCSS}
`

interface DividerProps {
  className?: string
  orientation: 'H' | 'V'
  thickness: number
}

export const Divider = styled.div<DividerProps>`
  ${({ orientation, theme, thickness }) =>
    orientation === 'H'
      ? css`
          width: 100%;
          margin: ${Math.round(thickness / 2)}px 0;
          border-bottom: 1px solid ${theme.palette.grey[100]};
        `
      : css`
          height: 100%;
          margin: 0 ${Math.round(thickness / 2)}px;
          border-right: 1px solid ${theme.palette.grey[100]};
        `}
`

export type ImageProps = React.HTMLAttributes<HTMLImageElement> & {
  h?: number
  w?: number
}

export const Image = styled.img<ImageProps>`
  height: ${({ h }) => (h ? `${h}px` : 'auto')};
  width: ${({ w }) => (w ? `${w}px;` : 'auto')};
`

type InlineProps = {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

export const Inline = ({
  children,
  className,
  style,
}: InlineProps): JSX.Element => {
  const element = React.Children.map(children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, { style: { display: 'inline' } })
      : child
  )

  return (
    <div className={className} style={style}>
      {element}
    </div>
  )
}

export const TrackingPixel = styled.img`
  height: 1;
  width: 1;
  display: none;
`

export const List = styled(FlexColumn)`
  overflow-y: auto;
`

export const BulletList = styled.ul`
  margin-top: 16px;
  margin-bottom: 16px;
  & > li {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`

/* PAGE AND TABLE COMPONENTS */
/* Use this components to build a page with a list or a table fast */

type PageWrapperProps = {
  background?: 'primary' | 'secondary'
  hasTabHeader?: boolean
}

export const PageWrapper = styled(FlexColumn)<PageWrapperProps>`
  max-width: calc(100% - 112px); // 56px padding on each side
  min-width: 800px;
  min-height: ${({ hasTabHeader }) =>
    `calc(100% - ${
      64 + (hasTabHeader ? 72 : 0)
    }px)`}; // 32px padding on top and bottom, plus 72px fer tab header if present
  background-color: ${({ background, theme }) =>
    theme.palette.background[background || 'primary']};
  overflow-x: auto;
  padding: 32px 56px;

  // TODO: temp fixes, remove when DS will be released
  .DS-table-tr-header th:first-child {
    border-left: none;
  }
`

export const PageHeader = styled(FlexRow)`
  justify-content: space-between; // To show the title on the left side and buttons on the right side
  margin-bottom: 24px;
`

export const TableWrapper = styled(Card)`
  ${flexColumn}
  align-self: stretch; // Always fit parent width
  flex: 1;
  padding: 32px 24px;
`

export const TableActionsWrapper = styled(FlexRow)`
  gap: 16px;
  margin-bottom: ${({ theme }) => theme.spacing.S};
`

export const TableSearchInput = styled(SearchInput)`
  max-width: 40%;
  min-width: 400px;
`

export const LargeModal = styled(Modal)`
  width: 850px;
`
