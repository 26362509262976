const getEnv = (key: string): string => process.env[key] || ''

export const version = getEnv('REACT_APP_VERSION')
export const env = getEnv('REACT_APP_ENV')
export const hostApp = getEnv('REACT_APP_HOST_APP')
export const hostSecure = getEnv('REACT_APP_HOST_SECURE')
export const hostApi = getEnv('REACT_APP_HOST_API')
export const hostFic = getEnv('REACT_APP_HOST_FIC')
export const hostCompute = getEnv('REACT_APP_HOST_COMPUTE')
export const ficCdn = getEnv('REACT_APP_FIC_CDN')
export const ficCdnGeneral = getEnv('REACT_APP_FIC_CDN_GENERAL')
export const stripePublicKey = getEnv('REACT_APP_STRIPE_PUBLIC_KEY')
export const oneSignalAppId = getEnv('REACT_APP_ONESIGNAL_APP_ID')
export const oneSignalSafariAppId = getEnv('REACT_APP_ONESIGNAL_SAFARI_APP_ID')
export const sentryDns = getEnv('REACT_APP_SENTRY_DSN')
export const tsPayDashboard = getEnv('REACT_APP_TS_PAY')
export const tsPayDigitalPos = getEnv('REACT_APP_TS_PAY_DIGITAL_POS')
export const googleMapsApiKey = getEnv('REACT_APP_GOOGLE_MAPS_API_KEY')
export const ficClientID = getEnv('REACT_APP_CLIENT_ID')

export const isProduction = env === 'production'
export const isDevelopment = env === 'development'
export const isLocal = env === 'local'

export const reportEngineHost = getEnv('REACT_APP_REPORT_ENGINE_HOST')

export const priceListsUrl = getEnv('REACT_APP_PRICE_LISTS_URL')
