import { GlobalStore } from '../../app/rootReducer'
import { EditTranchesModalData } from '.'
import SwapStorageStore, {
  CouponMassiveAssignment,
  DisconnectAccountantCompany,
  ImportedProductsData,
  MigrationsClientsData,
} from './swapStorageTypes'

export const getSwapStorage = (state: GlobalStore): SwapStorageStore =>
  state.swapStorage

export const getDisconnectAccountantCompany = (
  state: GlobalStore
): DisconnectAccountantCompany => state.swapStorage.disconnectAccountantCompany

export const selectCouponMassiveAssignment = (
  state: GlobalStore
): CouponMassiveAssignment => state.swapStorage.couponMassiveAssignment

export const selectMigrationsClientsData = (
  state: GlobalStore
): MigrationsClientsData => state.swapStorage.migrationsClientsData

export const selectImportedProductsData = (
  state: GlobalStore
): ImportedProductsData => state.swapStorage.importedProductsData

export const selectEditTranchesModalData = (
  state: GlobalStore
): EditTranchesModalData | undefined => state.swapStorage.editTranchesModalData
