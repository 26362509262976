import { BECallStatus } from '../../../common/types'
import { ImportedProductsData, MigrationsClientsData } from '.'
import SwapStorageStore, { CouponMassiveAssignment } from './swapStorageTypes'

export const couponMassiveAssignmentInitialState: CouponMassiveAssignment = {
  clients: [],
  counters: {},
}

export const migrationsClientsDataInitialState: MigrationsClientsData = {
  clients: [],
  counters: {},
  options: [],
}

export const importedProductsDataInitialState: ImportedProductsData = {
  products: [],
  vatTypes: [],
  isCleared: true,
}

const swapStorageInitialState: SwapStorageStore = {
  documentToTransform: undefined,
  tsPayPOSPayments: undefined,
  tsPayAutoActivateService: undefined,
  disconnectAccountantCompany: {
    fetchStatus: BECallStatus.IDLE,
  },
  couponMassiveAssignment: couponMassiveAssignmentInitialState,
  migrationsClientsData: migrationsClientsDataInitialState,
  importedProductsData: importedProductsDataInitialState,
}

export default swapStorageInitialState
